import {HttpClient} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {Room} from '@core/models/candidate/room';
import {BehaviorSubject} from 'rxjs';
import {AgoraService} from '../agora/agora.service';

@Injectable({
    providedIn: 'root',
})
export class PiPService {
    http = inject(HttpClient);

    pipClose$ = new BehaviorSubject<boolean>(false);
    pipData$ = new BehaviorSubject<{
        room: Room;
        service: AgoraService;
        mute: boolean;
        isFinished: boolean;
        isPremium: boolean;
        channelName: string;
    }>({
        room: undefined as unknown as Room,
        service: undefined as unknown as AgoraService,
        mute: false,
        isFinished: true,
        isPremium: true,
        channelName: '',
    });

    private setPiPData(
        room: Room,
        service: AgoraService,
        mute: boolean,
        isFinished: boolean,
        isPremium: boolean,
        channelName: string,
    ) {
        this.pipData$.next({
            room: room,
            service: service,
            mute: mute,
            isFinished: isFinished,
            isPremium: isPremium,
            channelName,
        });
    }

    get isClosed(): boolean {
        return this.pipClose$.getValue();
    }

    closePiP() {
        this.pipClose$.next(true);
    }

    openPiP() {
        this.pipClose$.next(false);
    }
}
