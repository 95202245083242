import {Injectable} from '@angular/core';
import AgoraRTC, {IAgoraRTCClient} from 'agora-rtc-sdk-ng';

import {RtcData, RtcDataUser} from '@core/models/agora/rtc-data';
import {IRtc} from '../../models/agora/rtc';
import {environment} from '@env/environment';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AgoraService {
    rtc: IRtc = {};

    rtcClient!: IAgoraRTCClient;
    rtcClientList;
    videoStreamType: number;
    options = {
        appId: environment.agoraToken,
        channel: '',
        token: '',
        uid: 0,
    };
    isDisconnected: boolean = false;

    remoteUsers!: BehaviorSubject<RtcDataUser[]>;

    clientMuted: boolean;

    constructor() {
        this.clientMuted = false;
        this.rtcClientList = new Map<string, RtcData>();
        this.videoStreamType = 0;
        AgoraRTC.setLogLevel(4);
        this.remoteUsers = new BehaviorSubject<RtcDataUser[]>([]);
        //AgoraRTC.Logger.setLogLevel(AgoraRTC.Logger.NONE)
    }
    //IAgoraRTCRemoteUser
    createRtcClient() {
        this.rtcClient = AgoraRTC.createClient({mode: 'live', codec: 'vp8'});
        this.rtcClient.setClientRole('audience');
        this.rtcClient.setRemoteDefaultVideoStreamType(this.videoStreamType);
    }

    async localUser(channel: string, token: string, uuid: number) {
        return this.rtcClient.join(this.options.appId, channel, token, uuid);
    }

    changeStreamType(type: number) {
        this.videoStreamType = type;
    }

    toggleMute(userId: number) {
        const user = this.remoteUsers.value.find(user => user.userId === userId);
        if (user) {
            user.isMuted = !user.isMuted;
        }
    }

    getClientMuted(): boolean {
        return this.clientMuted;
    }

    async leaveStream() {
        if (this.rtcClient != undefined) {
            this.rtcClient.removeAllListeners();
            this.remoteUsers.value.forEach(user => {
                user.data.audioTrack?.stop();
                user.data.videoTrack?.stop();
            });
            this.remoteUsers.next([]);
            return this.rtcClient.leave();
        }
    }

    clearRemoteUsers() {
        this.remoteUsers.next([]);
    }

    updateRemoteUser(userId: number, newRemoteUser: RtcDataUser) {
        const remoteUsers = this.remoteUsers.value;
        const index = remoteUsers.findIndex(user => user.userId === userId);
        if (index !== -1) {
            remoteUsers[index] = newRemoteUser;
            this.remoteUsers.next(remoteUsers);
        }
    }
}
